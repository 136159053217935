<mat-sidenav-container fullscreen >

  <mat-sidenav class="list_mob_menu" #sidenav mode="isMobile.matches ? 'side' : 'push'" role="navigation" position="end">

    <mat-icon class="list_mob_menu_close" (click)="sidenav.toggle()">close</mat-icon>

    <div class="contentCenter" fxLayout="row wrap" fxLayoutAlign="end center">
      <a href="{{pcUrl}}/funds/deposit.html" fxFlex="100" target="_blank" rel="noreferrer  nofollow" i18n-aria-label="header button 1|@@header_btn1" aria-label="DEPOSIT FUNDS" class="btn_small btn_red m_t_mob_s"  i18n="@@header_btn1">DEPOSIT FUNDS</a>
      <a href="{{pcUrl}}/registration.html" fxFlex="100" target="_blank" rel="noreferrer  nofollow" i18n-aria-label="header button 2|@@header_btn2" aria-label="OPEN ACCOUNT" class="btn_small btn_red m_t_mob_s" i18n="@@header_btn2">OPEN ACCOUNT</a>
      <a href="{{pcUrl}}/" fxFlex="100" target="_blank" rel="noreferrer  nofollow" i18n-aria-label="header button 3|@@header_btn3" aria-label="SIGN IN" class="btn_small btn_silver m_t_mob_s" i18n="@@header_btn3">SIGN IN</a>
    </div>


    <app-menu-list ></app-menu-list> <!-- (click)="sidenav.toggle()" -->

  </mat-sidenav>

  <mat-sidenav-content role="main" >
    <div class="top_line">
      <div class="contentCenter" fxLayout="row wrap" fxLayoutAlign="end center">
        <a href="{{pcUrl}}/funds/deposit.html" target="_blank" rel="noreferrer  nofollow" i18n-aria-label="header button 1|@@header_btn1" aria-label="DEPOSIT FUNDS" class="btn_small btn_red top_line_m_r" i18n="@@header_btn1">DEPOSIT FUNDS</a>
        <a href="{{pcUrl}}/registration.html" target="_blank" rel="noreferrer  nofollow" i18n-aria-label="header button 2|@@header_btn2" aria-label="OPEN ACCOUNT" class="btn_small btn_red top_line_m_r" i18n="@@header_btn2">OPEN ACCOUNT</a>
        <a href="{{pcUrl}}/" target="_blank" rel="noreferrer  nofollow" i18n-aria-label="header button 3|@@header_btn3" aria-label="SIGN IN" class="btn_small btn_silver" i18n="@@header_btn3">SIGN IN</a>


      </div>
    </div>
    <mat-toolbar color="primary" class="header" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxFlex="100">
        <app-header class="contentCenter header_top" fxLayout="row wrap" fxLayoutAlign="space-between center"></app-header>
        <button class="mob_menu_btn" mat-icon-button (click)="sidenav.toggle()" i18n-aria-label="header|mobile menu" aria-label="Mobile Menu">
          <mat-icon class="mob_menu_btn_ico">menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <main [style.min-height.px]="navService.clientHeight-256">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
    <app-top-button class="btn_to_top"></app-top-button>
  </mat-sidenav-content>
</mat-sidenav-container>
