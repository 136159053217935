<div class="top_sec platforms_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="platforms|top header">PLATFORMS</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 60, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 35, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.009, initialValue: 90, cssUnit:'%'}]"></div>


</div>


<div class="pl_sec1">
  <div class="contentCenter">
    <div class="pl_sec1_box" fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div fxFlex="100" fxFlex.gt-sm="55">
        <p class="pl_sec1_box_txt" i18n="platforms|section 1 text 1">MetaTrader 4 is One of the Most Powerful and Popular Online Trading Platforms in the Forex Industry</p>
        <div class="pl_sec1_box_btn">
          <a href="{{pcUrl}}/registration.html" target="_blank" rel="nofollow noreferrer" class="btn btn_white btn_mob" i18n-aria-label="platforms button 1|@@platforms_btn1" aria-label="OPEN DEMO ACCOUNT" i18n="@@platforms_btn1">OPEN DEMO ACCOUNT</a>
          <a href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg?utm_source=swissfs.com&utm_campaign=swissfs.com" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 2|@@platforms_btn2" aria-label="DOWNLOAD FOR MAC" class="btn btn_white btn_mac btn_mob" i18n="@@platforms_btn2">DOWNLOAD FOR MAC</a>
          <a href="https://download.mql5.com/cdn/web/bmfn.pltd/mt4/bmfn4setup.exe" class="btn btn_white btn_windows btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 3|@@platforms_btn3" aria-label="DOWNLOAD FOR PC" i18n="@@platforms_btn3">DOWNLOAD FOR PC</a>
          <a href="https://webtrader.swissfs.com/swissfs/live/" class="btn btn_white btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 41|@@platforms_btn4_1" aria-label="Live WebTrader" i18n="@@platforms_btn4_1">Live WebTrader</a>
          <a href="https://webtrader.swissfs.com/swissfs/demo/" class="btn btn_white btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 41|@@platforms_btn4_2" aria-label="Demo WebTrader" i18n="@@platforms_btn4_2">Demo WebTrader</a>
        </div>
      </div>
      <div class="pl_sec1_box_img" fxFlex="42" fxHide fxShow.gt-sm>
        <img  src="assets/platforms/monitor.png" srcset="assets/platforms/monitor@2x.png 2x" i18n-alt="platforms|img 1" alt="MetaTrader 4" >
      </div>
    </div>
  </div>
</div>


<div class="pl_sec2">
  <div class="contentCenter">
    <h2 class="pl_sec2_h" i18n="platforms|section 2 header 1">ABOUT THE PLATFORM</h2>
    <p class="pl_sec2_txt m_t_mid" i18n="platforms|section 2 txt 1">The award-winning platform MetaTrader 4 is designed to handle trade operations with a large number of financial instruments providing traders with the ability to implement technical analysis with chart functions using full power of automated strategies and indicators.</p>
    <div class="pillars_info" fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div class="pillars_info_col " fxFlex="100" fxFlex.gt-sm="49">
        <span class="pillars_info_col_n">01</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="platforms|section 2 header 2">High flexibility and trading security</h3>
          <p class="pillars_info_col_txt m_t_mid" i18n="platforms|section 2 txt 2">MetaTrader is available in more than 30 languages. Customizable user profiles allow you to store you favorite workspace or organize it on any MetaTrader terminal in seconds.</p>
          <p class="pillars_info_col_txt m_t_mob" i18n="platforms|section 2 txt 3"> All data exchange between the client terminal and trading server is 128-bit encrypted. The platform delivers more than enough protection to ensure the security of your trade transactions.</p>


      </div>
      <div class="pillars_info_col " fxFlex="100" fxFlex.gt-sm="49">
        <span class="pillars_info_col_n">02</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="platforms|section 2 header 3">Impressive analytical tools</h3>
        <p class="pillars_info_col_txt m_t_mid" i18n="platforms|section 2 txt 4">MetaTrader 4 platform offers a number of impressive analytical tools. There are nine timeframes available for each financial instrument that provide detailed analysis of quote dynamics.</p>
        <p class="pillars_info_col_txt m_t_mob" i18n="platforms|section 2 txt 5">Determine trends, define various shapes, apply objects one over another or determine entry and exit points. Print the charts of any instruments and conduct "on paper" analysis if necessary.</p>
      </div>
      <div class="pillars_info_col " fxFlex="100" fxFlex.gt-sm="49">
        <span class="pillars_info_col_n">03</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="platforms|section 2 header 4">Expert Advisor and Indicators</h3>
        <p class="pillars_info_col_txt m_t_mid" i18n="platforms|section 2 txt 6">Expert Advisors and Indicators will completely automate your trading experience. With over 50 built-in indicators and tools MT is ready to help you with automation of the trading, analyses of trends, forecast, and to determine the right entry and exit points.</p>
        <p class="pillars_info_col_txt m_t_mob" i18n="platforms|section 2 txt 7">Simply use the free-of-charge Code Base to get the complimentary Expert Advisors and Indicators developed by the professional traders or implement your own ideas using the MQL4 programming language.</p>


      </div>
      <div class="pillars_info_col " fxFlex="100" fxFlex.gt-sm="49">
        <span class="pillars_info_col_n">04</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="platforms|section 2 header 5">Mobile trading on Android and iOS</h3>
        <p class="pillars_info_col_txt m_t_mid" i18n="platforms|section 2 txt 8">Available on Android and iOS platforms, MetaTrader 4 mobile platform is your daily assistant and irreplaceable working tool. Stay mobile, trade in financial markets, get trade information and analyze it 24 hours a day from your mobile device.</p>
        <p class="pillars_info_col_txt m_t_mob" i18n="platforms|section 2 txt 9">Fully functional Forex trading, efficient arsenal of the most critical technical analysis tools includes 30 technical indicators.</p>

      </div>
    </div>

  </div>
</div>


<div class="blue_sec pl_sec3">
  <div class="contentCenter">
    <div class="blue_sec_box" fxLayout="row wrap" fxLayoutAlign="start stretch">
      <div fxFlex="100" fxFlex.gt-sm="40">
        <h2 class="blue_sec_box_h" i18n="platforms|section 3 header 1">METATRADER<br>for iPhone/iPad</h2>
        <p class="blue_sec_box_txt m_t_mid" i18n="platforms|section 3 txt 1">One of the most popular Forex trading platforms, MetaTrader 4, is now available on your iPhone or iPad absolutely free of charge.</p>
        <p class="blue_sec_box_txt m_t_mob" i18n="platforms|section 3 txt 2">Trade currencies with Swiss International.</p>
        <p class="blue_sec_box_txt m_t_mob" i18n="platforms|section 3 txt 3">With this application, you can control your account, trade in the financial markets and use 30 technical indicators for market analysis.</p>

      </div>
      <div class="blue_sec_box_img" fxFlex="40" fxHide fxShow.gt-sm>
        <img src="assets/platforms/ios.png" srcset="assets/platforms/ios@2x.png 2x" i18n-alt="platforms|img 2" alt="METATRADER for iPhone/iPad" style="max-width: 287px;">
      </div>
      <div class="blue_sec_box_app" fxFlex="100" fxFlex.gt-sm="20" fxLayout="column wrap" fxLayoutAlign="center center">
        <img class="blue_sec_box_app_qr"  src="assets/platforms/qr_ios.jpg" srcset="assets/platforms/qr_ios@2x.jpg 2x" i18n-alt="platforms|img 3" alt="MetaTrader 4 for iPhone/iPad QR CODE">
        <a href="https://download.mql5.com/cdn/mobile/mt4/ios?server=SwissDirectTrading-RealFX,SwissDirectTrading-DemoFX"  class="m_t_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 4|@@platforms_btn4" aria-label="MetaTrader 4 for iPhone/iPad Link" ><img  src="assets/platforms/app-store-badge-us-black.svg"  i18n-alt="platforms|img 4" alt="MetaTrader 4 for iPhone/iPad Link"></a>
      </div>
    </div>
  </div>
</div>


<div class="blue_sec pl_sec4">
  <div class="contentCenter">
    <div class="blue_sec_box" fxLayout="row wrap" fxLayoutAlign="end stretch">

      <div class="blue_sec_box_app" fxFlex="100" fxFlex.gt-sm="15" fxLayout="column wrap" fxLayoutAlign="center center" fxFlexOrder="2" fxFlexOrder.gt-sm="1">
        <img class="blue_sec_box_app_qr"  src="assets/platforms/qr_android.jpg" srcset="assets/platforms/qr_android@2x.jpg 2x" i18n-alt="platforms|img 5" alt="MetaTrader for Android QR CODE">
        <a href="https://download.mql5.com/cdn/mobile/mt4/android?server=SwissDirectTrading-DemoFX,SwissDirectTrading-RealFX" class="m_t_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 5|@@platforms_btn5" aria-label="MetaTrader for Android" ><img  src="assets/platforms/google-play-badge-us.svg" i18n-alt="platforms|img 6" alt="MetaTrader for Android"></a>
      </div>

      <div class="blue_sec_box_img" fxFlex="45" fxHide fxShow.gt-sm fxFlexOrder="1" fxFlexOrder.gt-sm="2">
        <img src="assets/platforms/android.png" srcset="assets/platforms/android@2x.png 2x" i18n-alt="platforms|img 7" alt="MetaTrader for Android" style="max-width: 288px;">
      </div>

      <div fxFlex="100" fxFlex.gt-sm="40" fxFlexOrder="1" fxFlexOrder.gt-sm="3">
        <h2 class="blue_sec_box_h" i18n="platforms|section 4 header 1">METATRADER<br>for Android</h2>
        <p class="blue_sec_box_txt m_t_mid" i18n="platforms|section 4 txt 1"> All Android users can trade, analyze the market using charts, watch the quotes 24 hours a day and examine the history of performed deals without giving up their favorite MetaTrader 4 platform.</p>
        <p class="blue_sec_box_txt m_t_mob" i18n="platforms|section 4 txt 2">By downloading MetaTrader 4 for Android for free traders get a convenient and reliable working tool that will always be with them.</p>
        <p class="blue_sec_box_txt m_t_mob" i18n="platforms|section 4 txt 3">The application has native support of Android powered tablet PCs!</p>
      </div>
    </div>
  </div>
</div>

<div class="pl_sec5">
  <div class="contentCenter">
    <div class="pl_sec5_box pillars_info m_t_tab" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="pillars_info_col " fxFlex="100" fxFlex.gt-sm="48">
        <h3 class="pillars_info_col_h" i18n="platforms|section 5 header 1">Metatrader FX</h3>
        <p class="pillars_info_col_txt m_t_mid" i18n="platforms|section 5 txt 1">Easy Navigation</p>
        <p class="pillars_info_col_txt m_t_mob" i18n="platforms|section 5 txt 2">Fixed spread</p>
        <p class="pillars_info_col_txt m_t_mob" i18n="platforms|section 5 txt 3">FX only</p>
        <a href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg?utm_source=swissfs.com&utm_campaign=swissfs.com" class="btn btn_blue btn_mac_w m_t_mid pl_sec5_right btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 6|@@platforms_btn6" aria-label="MAC OS" i18n="@@platforms_btn6">MAC OS</a>
        <a href="https://download.mql5.com/cdn/web/bmfn.pltd/mt4/bmfn4setup.exe" class="btn btn_blue btn_windows_w m_t_mid btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="platforms button 7|@@platforms_btn7" aria-label="WINDOWS" i18n="@@platforms_btn7">WINDOWS</a>
      </div>


    </div>
  </div>
</div>
