<div class="top_sec call_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="call-back|top header">CALL BACK</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 48, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 48, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 94, cssUnit:'%'}]"></div>

</div>




<div class="sec1_call">
  <div class="contentCenter">
    <div class="sec1_call_box p_z_cont">
      <h2 class="sec1_call_box_h" i18n="call-back|section 1 header 1">CALL BACK<br>REQUEST FORM</h2>
      <p class="sec1_call_box_txt" i18n="call-back|section 1 txt 1">Submit the form below and we will contact you according to your request, or within the shortest time possible.</p>
      <div class="m_t_mob">
        <app-callback-form class="black_form"></app-callback-form>
      </div>
    </div>
  </div>
</div>




