<div class="top_sec contact_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="contact-us|top header">CONTACT US</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.06, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.0085, initialValue: 75, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr4 fgr_position4" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.018, initialValue: 97, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr1 fgr_position5" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 83, cssUnit:'%'}]"></div>

</div>


<div class="sec1_contact">
  <div class="contentCenter">

    <div class="pillars_info p_z_cont contentCenter" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="pillars_info_col contact_s1_c1" fxFlex="100" fxFlex.gt-sm="28">
        <span class="pillars_info_col_n">01</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="contact-us|section 1 header 1">KUWAIT</h3>
        <h3 i18n="contact-us|section 1 header 2">(MAIN OFFICE)</h3>
        <div class="pillars_info_col_height">
          <p class="pillars_info_col_txt m_t_mid" i18n="contact-us|section 1 txt 1">CITY TOWER (AL MADINA TOWER) FLOOR 16</p>
          <p class="pillars_info_col_txt m_t_mob" i18n="contact-us|section 1 txt 2">KHALID IBN AL WALEED STREET SHARQ KUWAIT P.O.BOX 26635, SAFAT 13127</p>
        </div>
        <!--<a href="https://goo.gl/maps/kXZaNVzLnaSxnnbP6" target="_blank" rel="nofollow" class="btn_arrow_r btn_red_line m_t_tab">OPEN GOOGLE MAPS</a>-->
      </div>
      <div class="pillars_info_col contact_s1_c2" fxFlex="100" fxFlex.gt-sm="28">
        <span class="pillars_info_col_n">02</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="contact-us|section 1 header 3">KUWAIT</h3>
        <h3 i18n="contact-us|section 1 header 4">(JAHRA BRANCH)</h3>
        <div class="pillars_info_col_height">
          <p class="pillars_info_col_txt m_t_mid" i18n="contact-us|section 1 txt 3">JAHRA - A PIECE (93) - BUILDING (27)</p>
          <p class="pillars_info_col_txt m_t_mob" i18n="contact-us|section 1 txt 4">FIRST FLOOR OFFICE NO. (9 \ 10 \ 11)</p>
        </div>
        <!--<a href="" target="_blank" rel="nofollow" class="btn_arrow_r btn_red_line m_t_tab">OPEN GOOGLE MAPS</a>-->
      </div>
      <div class="pillars_info_col contact_s1_c3" fxFlex="100" fxFlex.gt-sm="28">
        <span class="pillars_info_col_n">03</span>
        <h3 class="pillars_info_col_h m_t_mid" i18n="contact-us|section 1 header 5">EGYPT</h3>
        <h3 i18n="contact-us|section 1 header 6">(CAIRO BRANCH)</h3>
        <div class="pillars_info_col_height">
          <p class="pillars_info_col_txt m_t_mid" i18n="contact-us|section 1 txt 5">MENA CALL CENTER</p>
          <p class="pillars_info_col_txt m_t_mob" i18n="contact-us|section 1 txt 6">HELIOPOLIS - CAIRO, EGYPT</p>
        </div>
        <!-- <a href="" target="_blank" rel="nofollow" class="btn_arrow_r btn_red_line m_t_tab">OPEN GOOGLE MAPS</a>-->
      </div>
    </div>

  </div>
</div>

<div class="sec2_contact">
  <div class="contentCenter">
    <div class="sec2_contact_box">
      <h2 class="sec2_contact_box_h" i18n="contact-us|section 2 header 1">CONTACT</h2>
      <h2 class="sec2_contact_box_h" i18n="contact-us|section 2 header 2">REQUEST FORM</h2>
      <h3 class="sec2_contact_box_txt" i18n="contact-us|section 2 header 3">Fill Your Contact Info.</h3>
      <app-feedback-form class="black_form m_t_mob"></app-feedback-form>
    </div>
  </div>
</div>
