<div class="top_sec about_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="company|top header">THE COMPANY</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 48, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 48, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 94, cssUnit:'%'}]"></div>

  <div class="anm_figure fgr2 fgr_position4" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.033, initialValue: 170, cssUnit:'%'}]"></div>

  <div class="anm_figure fgr1 fgr_position5" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.018, initialValue: 229, cssUnit:'%'}]"></div>

  <div class="anm_figure fgr3 fgr_position6" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.01, initialValue: 225, cssUnit:'%'}]"></div>

</div>


<div class="about_sec1">
  <div class="contentCenter">
    <div class="block_img img_right">
      <img src="/assets/company/about_sec1_1.webp" srcset="/assets/company/about_sec1_1@2x.webp 2x" i18n-alt="company| img 1" alt="The Business">

    </div>
    <div class="m_t_mid p_z_cont" fxLayout="row wrap" fxLayoutAlign="end start">
      <div class="block_txt">
        <h3 class="block_txt_h" i18n="company|section 1 header 1">The Business</h3>
        <p class="m_t_mid"><strong i18n="company|section 1 txt 1">Swiss International is a financial services company that facilitates the entire process of participating in global financial markets. Being an Integrated Service Provider we cover the entire process from "Research and Advisory" services to the ultimate "Execution and Clearing" of a transaction.</strong></p>
        <p class="m_t_mob" i18n="company|section 1 txt 2">To offer world-class quality in all the services we provide, we have worked hard to put together a highly qualified team of professionals. From the most junior marketing executive to the senior management.</p>
        <p class="m_t_mob" i18n="company|section 1 txt 3">A cosmopolitan culture consisting of people from various cultural, social and professional backgrounds allows us to interact with and accommodate effectively clients with differing backgrounds and expectations.</p>
        <p class="m_t_mob" i18n="company|section 1 txt 4">Some of the most respected and highly renowned names in the country provide the backing and support to our company, which lends us the highest level of recognition socially and professionally.</p>
      </div>
    </div>

    <!---->

    <div class="m_t_pc_r block_img img_left">
      <picture>
        <source data-srcset="/assets/company/about_sec1_2.webp" type="image/webp">
        <img src="/assets/company/about_sec1_2.webp" srcset="/assets/company/about_sec1_2@2x.webp 2x" i18n-alt="company|img 2" alt="The Products">
      </picture>
    </div>

    <div class="m_t_mid" fxLayout="row wrap" fxLayoutAlign="start start">
      <div class="block_txt">
        <h3 class="block_txt_h" i18n="company|section 1 header 2">The Products</h3>
        <p class="m_t_mid"><strong i18n="company|section 1 txt 5">Our reach in global markets is unrivalled in the local markets. Our coverage includes most geographical and product markets. From simple currency crosses in the spot market to the complexities of the derivatives market, we deal across the spectrum.</strong></p>
        <p class="m_t_mob" i18n="company|section 1 txt 6">The products/markets that we currently offer work on the principle of "leveraged trading" (leverage means trading with a face value much larger than the amount provided upfront as a deposit/security) and this leads to unmatchable rates of returns.</p>
        <p class="m_t_mob" i18n="company|section 1 txt 7">In the business of leveraged trading the two main types of transactions are ‘on the spot’ and "deferred" transactions, which are carried out in the spot and futures/forwards markets respectively. The company offers trading in both the spot and futures markets cost effectively.
        </p>
        <p class="m_t_mob" i18n="company|section 1 txt 8">The various products that we cover include all major currency crosses (e.g. EUR/USD, GBP/USD etc.), commodities (precious metals like gold and silver, energy products such as crude oil or even agricultural commodities such as coffee, cocoa, rubber etc.) and stock markets (futures on stock indices or on individual stocks). The above list is only a brief snapshot from our exhaustive range.</p>
        <p class="m_t_mob" i18n="company|section 1 txt 9">Upcoming additions to our range of products include capital guaranteed/fixed rate of return Investment vehicles with the long-term investor in mind.</p>
      </div>
    </div>

  </div>
</div>

<div class="about_sec2">
  <div class="contentCenter">
    <h2 class="about_sec2_h" i18n="company|section 2 header 1">Services</h2>
    <p class="about_sec2_txt m_t_mob" i18n="company|section 2 txt 1">Our range of services is categorized into two main categories:</p>
    <div class="about_sec2_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="about_sec2_box_col" fxFlex="100" fxFlex.gt-sm="49">
        <span class="about_sec2_box_col_n">01</span>
        <h3 class="about_sec2_box_col_h m_t_mob" i18n="company|section 2 header 2">Client support</h3>
        <p class="about_sec2_box_col_txt m_t_mob" i18n="company|section 2 txt 2">Includes all the facilities we can provide our highly valued clients ranging from completely managed "discretionary" accounts (your money is managed for you by top leading CTAs around the world OR by other Investment programs) on one extreme to "completely self managed" accounts (the investors trade by themselves) on the other.</p>
      </div>
      <div class="about_sec2_box_col" fxFlex="100" fxFlex.gt-sm="49">
        <span class="about_sec2_box_col_n">02</span>
        <h3 class="about_sec2_box_col_h m_t_mob" i18n="company|section 2 header 3">Product support</h3>
        <p class="about_sec2_box_col_txt m_t_mob" i18n="company|section 2 txt 3">Covers those areas that relate to the operational details of the market/product for example information on contract expiry dates (for futures), lot size, transaction cost structure, updates on changes in ‘margins’ ("margin" is the security deposit required to start a trade) etc.</p>
      </div>
    </div>

    <div class="m_t_tab" fxLayout="row wrap" fxLayoutAlign="start start">
      <div class="block_txt">
        <p i18n="company|section 2 txt 4">Our standard services like a 24-hour dealing desk (allowing access to markets at your convenience), daily research reports covering both fundamental and technical trading styles, daily end of day account statements delivered directly to you.</p>

        <p class="m_t_tab" i18n="company|section 2 txt 5">Ongoing expansion shall allow us to add highly renowned individual financial planning/advisory services to the existing range of financial services we provide.</p>

        <p class="m_t_tab" i18n="company|section 2 txt 6">Our ultimate endeavourer is to offer clients "A one-stop boutique for everything Finance".</p>
      </div>
    </div>

  </div>
</div>

<div class="about_sec3" id="risk-disclosure">
  <div class="contentCenter">
    <div class="about_sec3_box">
      <h2 class="about_sec3_box_h" i18n="company|section 3 header 1">Risk Disclosure</h2>
      <p class="m_t_tab" i18n="company|section 3 txt 1">This brief statement does not disclose all of the risks and other significant aspects of foreign exchange and CFDs products. In light of the risks, you should undertake such transactions only if you understand the nature of the contracts into which you are entering and the extent of your exposure to risk. Trading foreign exchange on margin carries is not suitable for many members of the public. Before deciding to trade the foreign exchange and CFDs products offered by Swiss International Financial Services, you should carefully consider whether trading is appropriate for you in light of your experience, objectives, financial resources and other relevant circumstances.</p>
      <p class="m_t_mob" i18n="company|section 3 txt 2">The possibility exists that you could sustain a loss of some or all of your deposited funds and therefore, you should not speculate with capital that you cannot afford to lose. You should be aware of all the risks associated with trading on margin. Swiss International Financial Services recommends you seek advice from a separate financial advisor.</p>
      <p class="m_t_mob" i18n="company|section 3 txt 3">There are risks associated with on-line trading systems including, but not limited to, software and hardware failure, and internet disconnection. Swiss International Financial Services does not control signal power, its reception or routing via Internet, configuration of your equipment or reliability of its connection, we cannot be responsible for communication failures, distortions or delays when trading via the Internet.</p>
      <p class="m_t_mob" i18n="company|section 3 txt 4">The content of this website is subject to change at any time without notice, and is provided for the sole purpose of assisting traders to make independent investment decisions. Swiss International Financial Services has taken reasonable measures to ensure the accuracy of the information on its website. However, we cannot guarantee its accuracy, and will not accept liability for any loss or damage that may arise directly or indirectly from the content or your inability to access the website.</p>
    </div>
  </div>
</div>
