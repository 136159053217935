<div class="news_sec1_info_box" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">

  <div class="news_sec1_info_box_col fadeIn animate delay_0{{i}}" fxFlex="100" fxFlex.gt-sm="30" fxFlex.gt-xs="45" *ngFor="let item of arrayNews;let i = index">
    <a routerLink="/blog/{{item.url}}.html" class="news_sec1_info_box_col_link" title="{{item.title}}"></a>
    <h4 class="news_sec1_info_box_col_h">{{item.title}}</h4>
    <div class="news_sec1_info_box_col_img" [ngStyle]="{'background-image': 'url(https://api.swissfs.com/blog/small/' + item.gallery + ')'}">
    </div>
    <p class="news_sec1_info_box_col_txt1 m_t_mob_s" [innerHtml]="item.intro"></p>
    <p class="news_sec1_info_box_col_txt2 m_t_mob_s">{{item.publishedDate | date: 'dd-MM-yyyy HH:mm'}}</p>

  </div>

</div>
