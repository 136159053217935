<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children && child.children.length > 0">
      <a mat-menu-item color="primary" routerLinkActive="active-link" [matMenuTriggerFor]="menu.childMenu">
        <mat-icon>{{child.iconName}}</mat-icon>
        <span>{{child.displayName}}</span>
      </a>
      <app-menu-item #menu [items]="child.children"></app-menu-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children || child.children.length === 0">
      <a mat-menu-item [routerLink]="parentPath+child.route" routerLinkActive="active-link"  [ngClass]="{'active': child.route ? router.isActive(child.route, true): false}">

        <span>{{child.displayName}}</span>
      </a>
    </span>
  </span>
</mat-menu>

