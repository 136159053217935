<div class="top_sec tutorials_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="tutorials|top header">Terms and Conditions</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>


<div class="terms_sec1">
  <div class="contentCenter_min">



    <p>Welcome to Swiss for Direct Trading in Currencies, Metals and Commodities (hereafter “SwissFS”). By accessing or using our services, you agree to be bound by these Terms and Conditions. Please read these terms carefully, as they outline the rules and obligations associated with using our services. If you do not agree with any part of these terms, please discontinue use of our services.</p>

    <h2 class="m_t_mid">1. Introduction</h2>
    <p class="m_t_mob_s">1.1. Swiss for Direct Trading in Currencies, Metals and Commodities (“SwissFS”, “we”, “us”, or “our”) is a financial services provider, offering leveraged trading with Instruments.</p>
    <p class="m_t_mob_s">1.2. These Terms and Conditions apply to all clients, users, and any others who access or use our services.</p>

    <h2 class="m_t_mid">2. Eligibility</h2>
    <p class="m_t_mob_s">2.1. By using our services, you confirm that you are at least 18 years of age or have reached the legal age in your jurisdiction.</p>
    <p class="m_t_mob_s">2.2. You warrant that all information provided to us is accurate, complete, and up-to-date.</p>

    <h2 class="m_t_mid">3. Financial Services and Advice</h2>
    <p class="m_t_mob_s">3.1. SwissFS provides general information and tools for managing and assessing financial investments.</p>
    <p class="m_t_mob_s">3.2. We do not provide personalized financial advice unless specifically agreed upon in a separate advisory agreement.</p>
    <p class="m_t_mob_s">3.3. All investment or trading activities involve risk. Past performance is not indicative of future results.</p>

    <h2 class="m_t_mid">4. Client Responsibilities</h2>
    <p class="m_t_mob_s">4.1. Clients are responsible for making their own financial decisions and understand the risks associated with financial investments.</p>
    <p class="m_t_mob_s">4.2. You agree not to use our services for any unlawful or unauthorized purpose.</p>

    <h2 class="m_t_mid">5. Account Registration and Security</h2>
    <p class="m_t_mob_s">5.1. To access some features, you may be required to create an account with a username and password. You are responsible for maintaining the confidentiality of your account information.</p>
    <p class="m_t_mob_s">5.2. You agree to notify us immediately of any unauthorized use of your account or any other security breach.</p>


    <h2 class="m_t_mid">6. Intellectual Property</h2>
    <p class="m_t_mob_s">7.1. All content, trademarks, service marks, and logos used in connection with our services are the intellectual property of SwissFS and/or any associated 3rd parties and are protected by law.</p>
    <p class="m_t_mob_s">7.2. You are granted a limited, non-exclusive, non-transferable license to access and use our content for personal or business use only.</p>

    <h2 class="m_t_mid">8. Limitation of Liability</h2>
    <p class="m_t_mob_s">8.1. To the fullest extent permitted by law, SwissFS shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services.</p>
    <p class="m_t_mob_s">8.2. This includes, but is not limited to, loss of profits, trading losses, or any damages resulting from errors, omissions, interruptions, or delays.</p>

    <h2 class="m_t_mid">9. Indemnification</h2>
    <p class="m_t_mob_s">9.1. You agree to indemnify, defend, and hold harmless Swiss for Direct Trading in Currencies, Metals and Commodities from and against all losses, expenses, damages, and costs arising out of your use of our services.</p>

    <h2 class="m_t_mid">10. Termination</h2>
    <p class="m_t_mob_s">10.1. We reserve the right to suspend or terminate your access to our services for any reason, including violation of these Terms and Conditions.</p>
    <p class="m_t_mob_s">10.2. Upon termination, your right to use our services will immediately cease.</p>

    <h2 class="m_t_mid">11. Governing Law and Dispute Resolution</h2>
    <p class="m_t_mob_s">11.1. These Terms and Conditions shall be governed by and construed in accordance with the laws of Kuwait.</p>
    <p class="m_t_mob_s">11.2. Any disputes arising from these Terms and Conditions or our services shall be subject to the exclusive jurisdiction of the courts in Kuwait.</p>

    <h2 class="m_t_mid">12. Amendments</h2>
    <p class="m_t_mob_s">12.1. We reserve the right to amend these Terms and Conditions at any time, with or without notice. The latest version will be posted on our website.</p>
    <p class="m_t_mob_s">12.2. Continued use of our services constitutes your acceptance of any changes to these terms.</p>

    <h2 class="m_t_mid">13. Online Payments Disclaimer:</h2>
    <p class="m_t_mob_s">SwissFS uses highly secure Streamline Internet Payment System. Neither SwissFS nor any of its affiliates and employees will be liable to or have any responsibility of any kind for any loss that you incur in the event of any deficiency in the services of the available online providers</p>

    <p class="m_t_mid">Card Funding Service:</p>
    <p class="m_t_mob_s">The funding of an account by payment card is allowed only by means of a payment card which belongs to, and is registered to an account of the same person as the
    account holder with SwissFS. We do not accept transfers from third parties.</p>
    <p class="m_t_mob_s">Visa/MasterCard Credit/Debit Cards are accepted for funding. We do not charge any fee for funding via payment card. The base currency is USD (US Dollar) for payment. Should you make a payment by a card having a different base currency an additional conversion fee may apply.</p>


    <p class="m_t_mid">Disclaimer:</p>
    <p class="m_t_mob_s"> In no event shall SwissFS be liable to the user or any other party for any damages, losses, expenses or costs whatsoever (including without limitation, any direct, indirect, special, incidental or consequential damages, loss of profits or lost opportunity) arising in connection with your use of this website, or reliance on any
    information, materials or online services provided at this website, regardless of the form of action.</p>

    <p class="m_t_mid">Terms and Conditions on Credit/Debit Card Funding</p>

    <p class="m_t_mid">Please note that for credit and debit card funding, the following terms and conditions apply:</p>

    <ol class="terms_sec1_listN m_t_mid">
      <li>The payment service provider:
        <ul class="terms_sec1_listC m_t_mob_s" >
          <li>Shall not be able to give you a cash refund for a payment made using a credit or debit card, unless law expressly requires this; </li>
          <li>Shall not be liable for the non-execution or defective execution of the transaction where you have provided us with inaccurate payment details; </li>
          <li>Shall not be liable for any delay or failure to carry out any of our obligations if (but not limited to) such failure is due to:
            <ul class="terms_sec1_listCE m_t_mob_s">
              <li>circumstances beyond our reasonable control;</li>
              <li>any cessation or interruption or any part of the services which are due to any act or omission of a card scheme, acquirer or other financial institution and is not caused by a default on our part;</li>
              <li>us having to comply with any relevant requirement under the scheme rules or any applicable law or regulation;</li>
              <li>your failure to provide complete and/or correct data to us or other financial institution.</li>

            </ul>
          </li>
          <li>May suspend the processing of any transaction where we reasonably believe that the transaction may be fraudulent or involves any criminal
            activity, until the satisfactory completion of investigation by us or any regulatory authority;
          </li>
          <li>Shall not be liable to you for any loss of profits, loss of data, loss of business or goodwill, losses incurred by third parties or any indirect or consequential loss or damage, howsoever arising. We shall have no liability to you for any
            inaccuracy in the information that we or any other financial institution may provide to any third parties, such disclosure being conducted in line with applicable data protection legislation or guidelines;
          </li>
          <li>Shall not be responsible for ensuring that data is delivered correctly to your acquiring bank or authorized processor in a way, which is acceptable to the acquiring bank or authorized processor, nor for any connection difficulties that are outside of our control. We accept no responsibility for service levels of your acquiring bank or any other authorized processors’ networks or any other third party product supplied, such as an Internet Service Provider (ISP) or other bandwidth provider of any description;</li>
          <li>Shall not be able to provide any guarantee as to the time any incident (provided that such incident is directly related to SCM and/or the payment service provider) may take to be resolved.</li>
        </ul>
      </li>
      <li>In the event where any payment order by you has been refused, such payment will be deemed not to have been received for the purposes of execution times and liability for non-execution or defective execution.</li>
    </ol>
    <hr class="m_t_mid">
    <p><strong>By using our services, you confirm that you have read, understood, and agree to these Terms and Conditions.</strong></p>

  </div>
</div>
