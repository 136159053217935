import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss']
})
export class CallBackComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }


}
