import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/overlay';
import {MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {NavService} from '@core/nav.service';
import {DOCUMENT, getLocaleDirection} from '@angular/common';


@Component({
  selector: 'app-layout-manuals',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebLayoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sidenav') appDrawer: ElementRef;
  @ViewChild(MatSidenavContainer) public readonly sidenavContainer: MatSidenavContainer;
  @ViewChild(MatSidenavContent) contentSrollable: CdkScrollable;
  @ViewChild(MatSidenavContent, {read: ElementRef}) public readonly contentElement: ElementRef;

  public theme = 'my-light-theme';


  // sanitizer.bypassSecurityTrustUrl('/'+)
  constructor(@Inject(DOCUMENT) public document: any,
              private renderer: Renderer2,
              private scrollDispatcher: ScrollDispatcher,
              public navService: NavService) {
    // light dark theme
    this.renderer.addClass(this.document.body, this.theme);

    const direction = getLocaleDirection(this.navService.locale); // 'rtl' or 'ltr'
    // this.renderer.addClass(this.document.firstElementChild.dir, direction);
    this.renderer.setAttribute(this.document.firstElementChild, 'dir', direction);

  }

  pcUrl = this.navService.env.pcUrl;


  ngAfterViewInit() {

    // initialization navService
    this.navService.appDrawer = this.appDrawer;
    this.navService.appDrawerContentElement = this.contentElement;
    this.navService.appDrawerContentScrollable = this.contentSrollable;
    // subscribe
    this.scrollDispatcher.scrolled()
      .subscribe(event => {
        this.navService.appDrawerContentScrolledCb(event);
      });
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.theme);
  }

  login() {
    alert('login');
  }

}



