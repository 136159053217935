<div class="top_sec bonus_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="bonus|top header">30% BONUS<br>
        FROM YOUR DEPOSIT</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>




<div class="sec1_bonus">
  <div class="contentCenter">
   <div class="m_t_pc_r block_img img_left">

        <img src="/assets/company/about_sec1_2.webp" srcset="/assets/company/about_sec1_2@2x.webp 2x" i18n-alt="bonus|bonus img 1" alt="30
        % DEPOSIT BONUS">

    </div>

    <div class="m_t_mid" >
      <h2 class="block_txt_h" i18n="bonus|section 1 header 1">30% DEPOSIT BONUS</h2>
      <div class="block_txt">

        <p class="m_t_mid" i18n="bonus|section 1 txt 1">Swiss For Trading in Currencies, Metals and Commodities Co (hereinafter "SwissFS") is offering all clients a 30% bonus on all deposits made during the month. Simply make a qualifying deposit and have it met with a FREE bonus by SwissFS!</p>

        <h2 class="block_txt_h m_t_mid" i18n="bonus|section 1 header 2">HOW IT WORKS?</h2>
        <p class="m_t_mid" i18n="bonus|section 1 txt 2">New or existing accounts at Swiss For Trading in Currencies, Metals and Commodities Co who enroll in the Bonus Program and make a qualifying deposit will receive a deposit bonus up to $17,500! The more you deposit, the more bonus funds you receive. Trading requirements do apply. See the <a href="https://docs.swissfs.com/swissfs/en/BonusTerms.pdf" target="_blank" rel="dofollow" style="color: #181d1e;text-decoration: underline;"  aria-label="Terms and Conditions">Terms and Conditions</a> for more information.</p>
        <p class="m_t_mid" i18n="bonus|section 1 txt 3">In order to apply for a bonus program, please proceed to the bonus section of the Swiss For Trading in Currencies, Metals and Commodities Co Private Cabinet.</p>
        <a href="{{pcUrl}}/login.html" target="_blank" rel="nofollow" i18n-aria-label="bonus button 1|@@button1" aria-label="GET BONUS" class="btn btn_mob btn_red m_t_mid" i18n="@@button1">GET BONUS</a>

      </div>
    </div>

  </div>
</div>


<div class="sec2_bonus">
  <div class="contentCenter">
    <table class="table_bonus">
      <thead>
        <tr>
          <td i18n="bonus|table td 1">Deposit</td>
          <td i18n="bonus|table td 2">Bonus</td>
          <td i18n="bonus|table td 3">Required Lots<br>(Round Turn Requirement)</td>
        </tr>
      </thead>
      <tbody>
     <!-- <tr> <td> $500-$999 </td> <td> $125 </td> <td> 25 </td> </tr>
      <tr> <td> $1,000-$1,999 </td> <td> $250 </td> <td> 50 </td> </tr>
      <tr> <td> $2,000-$2,999 </td> <td> $500 </td> <td> 100 </td> </tr>
      <tr> <td> $3,000-$3,999 </td> <td> $750 </td> <td> 150 </td> </tr>
      <tr> <td> $4,000-$4,999 </td> <td> $1000 </td> <td> 200 </td> </tr>
      <tr> <td> $5,000-$5,999 </td> <td> $1,250 </td> <td> 250 </td> </tr>
      <tr> <td> $6,000-$6,999 </td> <td> $1,500 </td> <td> 300 </td> </tr>
      <tr> <td> $7,000-$7,999 </td> <td> $1,750 </td> <td> 350 </td> </tr>
      <tr> <td> $8,000-$8,999 </td> <td> $2,000 </td> <td> 400 </td> </tr>
      <tr> <td> $9,000-$9,999 </td> <td> $2,250 </td> <td> 450 </td> </tr>
      <tr> <td> $10,000-$14,999 </td> <td> $2,500 </td> <td> 500 </td> </tr>
      <tr> <td> $15,000-$19,999 </td> <td> $3,750 </td> <td> 750 </td> </tr>
      <tr> <td> $20,000-$24,999 </td> <td> $5,000 </td> <td> 1000 </td> </tr>
      <tr> <td> $25,000-$29,999 </td> <td> $6,250 </td> <td> 1250</td> </tr>
      <tr> <td> $30,000-$34,999 </td> <td> $7,500 </td> <td> 1500 </td> </tr>
      <tr> <td> $35,000-$39,999 </td> <td> $8,750 </td> <td> 1750 </td> </tr>
      <tr> <td> $40,000-$49,999 </td> <td> $10,000 </td> <td> 2000 </td> </tr>
      <tr> <td> $50,000 and above </td> <td> $12,500</td> <td> 2500 </td> </tr>-->

     <!--<tr> <td> $500-$999 </td> <td> $175 </td> <td> 30 </td> </tr>
     <tr> <td> $1,000-$1,999 </td> <td> $350 </td> <td> 60 </td> </tr>
     <tr> <td> $2,000-$2,999 </td> <td> $700 </td> <td> 100 </td> </tr>
     <tr> <td> $3,000-$3,999 </td> <td> $1,050 </td> <td> 180 </td> </tr>
     <tr> <td> $4,000-$4,999 </td> <td> $1,400 </td> <td> 240 </td> </tr>
     <tr> <td> $5,000-$5,999 </td> <td> $1,750 </td> <td> 300 </td> </tr>
     <tr> <td> $6,000-$6,999 </td> <td> $2,100 </td> <td> 360 </td> </tr>
     <tr> <td> $7,000-$7,999 </td> <td> $2,450 </td> <td> 420 </td> </tr>
     <tr> <td> $8,000-$8,999 </td> <td> $2,800 </td> <td> 480 </td> </tr>
     <tr> <td> $9,000-$9,999 </td> <td> $3,150 </td> <td> 540 </td> </tr>
     <tr> <td> $10,000-$14,999 </td> <td> $3,500 </td> <td> 600 </td> </tr>
     <tr> <td> $15,000-$19,999 </td> <td> $5,250 </td> <td> 900 </td> </tr>
     <tr> <td> $20,000-$24,999 </td> <td> $7,000 </td> <td> 1200 </td> </tr>
     <tr> <td> $25,000-$29,999 </td> <td> $8,750 </td> <td> 1500</td> </tr>
     <tr> <td> $30,000-$34,999 </td> <td> $10,500 </td> <td> 1800 </td> </tr>
     <tr> <td> $35,000-$39,999 </td> <td> $12,250 </td> <td> 2100 </td> </tr>
     <tr> <td> $40,000-$49,999 </td> <td> $14,000 </td> <td> 2400 </td> </tr>
     <tr> <td> $50,000 and above </td> <td> $17,500</td> <td> 3000 </td> </tr>-->

     <tr> <td> $500-$999 </td> <td> $150 </td> <td> 30 </td> </tr>
     <tr> <td> $1,000-$1,999 </td> <td> $300 </td> <td> 60 </td> </tr>
     <tr> <td> $2,000-$2,999 </td> <td> $600 </td> <td> 100 </td> </tr>
     <tr> <td> $3,000-$3,999 </td> <td> $900 </td> <td> 180 </td> </tr>
     <tr> <td> $4,000-$4,999 </td> <td> $1,200 </td> <td> 240 </td> </tr>
     <tr> <td> $5,000-$5,999 </td> <td> $1,500 </td> <td> 300 </td> </tr>
     <tr> <td> $6,000-$6,999 </td> <td> $1,800 </td> <td> 360 </td> </tr>
     <tr> <td> $7,000-$7,999 </td> <td> $2,100 </td> <td> 420 </td> </tr>
     <tr> <td> $8,000-$8,999 </td> <td> $2,400 </td> <td> 480 </td> </tr>
     <tr> <td> $9,000-$9,999 </td> <td> $2,700 </td> <td> 540 </td> </tr>
     <tr> <td> $10,000-$14,999 </td> <td> $3,000 </td> <td> 600 </td> </tr>
     <tr> <td> $15,000-$19,999 </td> <td> $4,500 </td> <td> 900 </td> </tr>
     <tr> <td> $20,000-$24,999 </td> <td> $6,000 </td> <td> 1200 </td> </tr>
     <tr> <td> $25,000-$29,999 </td> <td> $7,500 </td> <td> 1500</td> </tr>
     <tr> <td> $30,000-$34,999 </td> <td> $9,000 </td> <td> 1800 </td> </tr>
     <tr> <td> $35,000-$39,999 </td> <td> $10,500 </td> <td> 2100 </td> </tr>
     <tr> <td> $40,000-$49,999 </td> <td> $12,000 </td> <td> 2400 </td> </tr>
     <tr> <td> $50,000 and above </td> <td> $15,000</td> <td> 3000 </td> </tr>


      </tbody>
    </table>

    <div class="block_txt">

      <p class="m_t_tab" i18n="bonus|info text 1">* 1 lot shall be equal to 1 industry standard lot or 10 mini lots on MetaTrader 4.</p>
      <p class="m_t_mob" i18n="bonus|info text 2">* is for Forex trading only. Round Turn Requirements are calculated ONLY on Forex Products, no CFDs on Futures, Commodities or Equities.</p>
      <p class="m_t_mob" i18n="bonus|info text 3">* Any internal transfer from Swiss For Trading in Currencies, Metals and Commodities Co accounts shall not be considered as a deposit for Promotion purposes.</p>
      <p class="m_t_mob" i18n="bonus|info text 4">In order to apply for a bonus program, please proceed to the bonus section of the Private Cabinet</p>
      <a href="{{pcUrl}}/login.html" target="_blank" rel="nofollow" i18n-aria-label="bonus button 1|@@button2" aria-label="GET BONUS" class="btn btn_mob btn_red m_t_mid" i18n="@@button2">GET BONUS</a>

    </div>

  </div>
</div>

<div class="sec3_bonus">
  <div class="contentCenter">
    <div class="sec3_bonus_box">
      <h2 class="sec3_bonus_box_h" i18n="bonus|section 3 header 1">BONUS<br> REQUEST FORM</h2>
      <h3 class="sec3_bonus_box_txt" i18n="bonus|section 3 header 2">Fill Your Contact Info.</h3>
      <div class="m_t_mob">
        <app-bonus-form class="black_form"></app-bonus-form>
      </div>
    </div>
  </div>
</div>
