<div class="top_sec bonus_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="bonus|top header">Blog</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>




<div class="news_sec1">
  <div class="contentCenter">
    <div class="news_sec1_info" fxLayout="row wrap" fxLayoutAlign="space-between end">

     <div class="news_sec1_info_box" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">


        <div class="news_sec1_info_box_col fadeIn animate delay_0{{i}}" fxFlex="100" fxFlex.gt-sm="30" fxFlex.gt-xs="45" *ngFor="let item of arrayNews; let i = index">
          <a routerLink="/blog/{{item.url}}.html" class="news_sec1_info_box_col_link" title="{{item.title}}"></a>
          <h4 class="news_sec1_info_box_col_h">{{item.title}}</h4>
          <div class="news_sec1_info_box_col_img" [ngStyle]="{'background-image': 'url(https://api.swissfs.com/blog/small/' + item.gallery + ')'}">
          </div>
          <p class="news_sec1_info_box_col_txt1 m_t_mob_s" [innerHtml]="item.intro"></p>
          <p class="news_sec1_info_box_col_txt2 m_t_mob_s">{{item.publishedDate | date: 'dd-MM-yyyy HH:mm'}}</p>

        </div>


      </div>
     <mat-paginator [length]="length"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     (page)="getPaginatorData($event)"
                     aria-label="Select page" fxFlex="100" class="m_t_tab">
      </mat-paginator>

      <div *ngIf="pageEvent">
        <h5>Page Change Event Properties</h5>
        <div>List length: {{pageEvent.length}}</div>
        <div>Page size: {{pageEvent.pageSize}}</div>
        <div>Page index: {{pageEvent.pageIndex}}</div>
      </div>
    </div>
  </div>
</div>

