<div class="top_sec bonus_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="bonus|top header">{{news?.title}}</h1>
    </div>
  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>


<div class="news_conent">
    <div class="news_conent_info">
      <div fxLayout="row wrap" fxLayoutAlign="end start">
        <a routerLink="/blog.html" class="news_conent_info_link">Read All News &rarr;</a>
      </div>
      <img  class="news_conent_info_img fadeInUp animate delay_03" src="https://api.swissfs.com/blog/big/{{gallery}}" alt="{{news?.title}}">
      <div class="news_conent_info_txt fadeInUp animate duration_07" [innerHtml]="trustedHtml"></div>
    </div>
</div>

