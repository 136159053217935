<div fxFlex="20">
  <a routerLink="/" class="logo_header" title="SwissFS" aria-label="SwissFS"></a>
</div>
<div fxFlex="80" fxLayout="row wrap" fxLayoutAlign="end center">
  <app-menu ></app-menu>
<!--  <div class="menu_lang">
   <a class="menu_lang_link" href="/{{localesList.lang}}">{{localesList.label}}</a>
  </div>-->

</div>
