import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // const authReq = request.clone({
    //   setHeaders: {Authorization: `${this.profileService.getToken()}`}
    // });
    return next.handle(request).pipe(  // request OR authReq
      tap(event => {
        if (event instanceof HttpResponse){
          // console.log('Server response', event);
        }
      }, err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401){console.log('Unauthorized', err); }
        }
      })
    );
  }

 // https://webdraftt.com/tutorial/angular-http-interceptor
}
