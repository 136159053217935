<div class="top_sec bonus_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="bonus|top header">Blog</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>




<div class="news_sec1">
  <div class="contentCenter">
    <div class="news_sec1_info" >
       <iframe #htmliFrameElement (load)="onLoad()"  width="100%" height="1000px" src="https://blog.swissfs.com/" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0" scrolling="no"></iframe>

    </div>
  </div>
</div>

