import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {INews, NewsService} from '../../../api/news/news.service';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-news-one',
  templateUrl: './news-one.component.html',
  styleUrls: ['./news-one.component.scss']
})
export class NewsOneComponent implements OnInit {

  public newsURL: string;
  public news: INews;
  public gallery: [];
  trustedHtml: any;

  constructor(route: ActivatedRoute,
              private newsApi: NewsService,
              private domSanitizer: DomSanitizer,
              private metaTagService: Meta,
              private title: Title) {
    this.trustedHtml = '';
    route.params.subscribe(params => {this.newsURL = params.page.substring(0, params.page.length - 5); });
  }

  ngOnInit(): void {
    this.newsApi.getOneNews(this.newsURL).subscribe( news => {
      this.news = news;

      const tmp = JSON.parse(news.news);
      console.log('news', tmp);

      this.trustedHtml = this.domSanitizer.bypassSecurityTrustHtml(tmp.root);
     // this.curent.root = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.curent.root) as string);
      console.log('trustedHtml', this.trustedHtml);
      this.title.setTitle(this.news.title);
      this.metaTagService.updateTag({name: 'description', content: this.news.seoDesc});
      this.metaTagService.updateTag({name: 'keywords', content: this.news.keywords});
      this.metaTagService.updateTag({property: 'og:title', content: this.news.title});
      this.metaTagService.updateTag({property: 'og:description', content: this.news.seoDesc});

      const tmpGallery = JSON.parse(news.gallery);
      for (const k in tmpGallery) { this.gallery = tmpGallery[k]; }

    });
  }

}
