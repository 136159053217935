<div class="top_sec home_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="home|top header">TRADE<br> ANYTIME ANYWHERE</h1>

      <div class="m_t_mob" fxFlex="100">
        <a href="{{pcUrl}}/registration.html" class="btn_arrow_w btn_red btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="home btn 1|@@home_btn1" aria-label="OPEN ACCOUNT" i18n="@@home_btn1">OPEN ACCOUNT</a>
      </div>
      <div class="m_t_mob" fxFlex="100">
        <a href="{{pcUrl}}/login.html" class="btn_arrow_w btn_red btn_mob" target="_blank" rel="nofollow noreferrer" i18n-aria-label="home button 2|@@home_btn2" aria-label="LOGIN" i18n="@@home_btn2">LOGIN</a>
      </div>
    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.06, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.0085, initialValue: 75, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr4 fgr_position4" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.018, initialValue: 97, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr1 fgr_position5" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 83, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position6" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.0085, initialValue: 131, cssUnit:'%'}]"></div>


</div>

<div class="index_sec2">
  <div class="index_sec2_box contentCenter" fxLayout="row wrap" fxLayoutAlign="start center">
    <h2 class="index_sec2_box_h m_t_mob_s white_color" fxFlex="100" i18n="home|section 2 header">ENJOY YOUR BONUS</h2>
    <p class="index_sec2_box_txt width280 m_t_mob white_color" i18n="home|section 2 txt 1">Take your 30% BONUS FROM YOUR DEPOSIT</p>
    <div fxFlex="100">
      <a routerLink="/bonus.html" class="btn_arrow_w btn_red_w m_t_mob btn_mob" i18n-aria-label="home button 3|@@home_btn3" aria-label="DEPOSIT BONUS" i18n="@@home_btn3">DEPOSIT BONUS</a>
    </div>
  </div>
</div>

<div class="index_sec3">
  <div class="contentCenter">
    <div class="index_sec3_box" >
      <h2 class="index_sec3_box_h" i18n="home|section 3 header">MetaTrader Platform</h2>
      <p class="m_t_mob_s"><strong i18n="home|section 3 txt 1">OFFERS A FAMILIAR TRADING INTERFACE TO SEASONED TRADERS THAT COVERS ALL BROKERAGE AND TRADING ACTIVITIES</strong></p>
      <p class="m_t_mob_s" i18n="home|home|section 3 txt 2">MT 4 has been around for the past 10 years and offers a familiar interface to a wide variety or professional trades and introducing brokers. </p>
      <p class="m_t_mob_s" i18n="home|home|section 3 txt 3">The platform provides a wide range of features for traders, including various execution technologies, unlimited charting, large number of technical indicators and line studies, custom indicators and Expert Advisor trading.</p>
      <div class="index_sec3_box_btn">
        <a routerLink="/platforms.html" class="btn btn_mob btn_red" i18n-aria-label="home button 4|@@home_btn4" aria-label="TRY A DEMO ACCOUNT" i18n="@@home_btn4">TRY A DEMO ACCOUNT</a>
        <a routerLink="/platforms.html" class="btn btn_mob btn_black_line" i18n-aria-label="home button 5|@@home_btn5" aria-label="ABOUT MT4" i18n="@@home_btn5">ABOUT MT4</a>
      </div>
    </div>
  </div>
</div>

<div class="index_sec4">
  <div class="pillars_info contentCenter" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="pillars_info_col indx_s4_c1" fxFlex="100" fxFlex.gt-sm="27">
      <span class="pillars_info_col_n">01</span>
      <h3 class="pillars_info_col_h m_t_mid" i18n="home|section 4 header 1">WIDE VARIETY OF FINANCIAL INSTRUMENTS AND MARKETS</h3>
      <p class="pillars_info_col_txt m_t_mid" i18n="home|section 4 txt 1">Trade or hedge your portfolio across numerous products and time zones.</p>
      <p class="pillars_info_col_txt m_t_mob" i18n="home|section 4 txt 2">With over 100+ Currencies and CFDs, trading sessions are active 24 hours, 6 days a week.</p>
    </div>
    <div class="pillars_info_col indx_s4_c2" fxFlex="100" fxFlex.gt-sm="27">
      <span class="pillars_info_col_n">02</span>
      <h3 class="pillars_info_col_h m_t_mid" i18n="home|section 4 header 2">TRANSPARENT PRICING, COMPETITIVE SPREADS & MARGINS</h3>
      <p class="pillars_info_col_txt m_t_mid" i18n="home|section 4 txt 3">Our commitment to fair prices offers the best experience in CFD trading. Competitive spreads represent real interbank market conditions.</p>
      <p class="pillars_info_col_txt m_t_mob" i18n="home|section 4 txt 4">leverage is totally flexible and customizable to your needs.</p>
    </div>
    <div class="pillars_info_col indx_s4_c3" fxFlex="100" fxFlex.gt-sm="27">
      <span class="pillars_info_col_n">03</span>
      <h3 class="pillars_info_col_h m_t_mid" i18n="home|section 4 header 3">WIDE CHOICE OF TRADING PLATFORMS AND IB SERVICES</h3>
      <p class="pillars_info_col_txt m_t_mid" i18n="home|section 4 txt 5">From handling accounts of Retail Customers to servicing Introducing Brokers and Omnibus partnerships, Swiss International offers it all.</p>
      <p class="pillars_info_col_txt m_t_mob" i18n="home|section 4 txt 6"> Our professionals are ready to provide you the first class assistance anytime.</p>
    </div>
  </div>
</div>


<div class="index_sec5">
  <div class="index_sec5_box contentCenter" >
    <h2 class="index_sec5_box_h width960" i18n="home|section 5 header 1">UNIQUE SOLUTIONS FOR IB/OMNIBUS/MONEY MANAGEMENT</h2>
    <h2 class="index_sec5_box_hbig" i18n="home|section 5 header 2">BUSINESSES</h2>
    <ul class="list_linered m_t_tab">
      <li i18n="home|section 5 list txt 1">State of the art Introducing Broker/Omnibus center solutions</li>
      <li i18n="home|section 5 list txt 2">White Labels for MetaTrader platforms</li>
      <li i18n="home|section 5 list txt 3">Multi Account Management solutions</li>
      <li i18n="home|section 5 list txt 4">Free IB websites</li>
    </ul>
    <a routerLink="/forpartners.html" class="btn btn_mob btn_black_line m_t_mid" i18n-aria-label="home button 6|@@home_btn6" aria-label="ABOUT PARTNERSHIP" i18n="@@home_btn6">ABOUT PARTNERSHIP</a>
  </div>
</div>

<div class="index_sec7" >
  <div class="index_sec7_box contentCenter" >
    <h2 class="index_sec7_h">Blog</h2>
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <a routerLink="/blog.html" class="index_sec7_link">Read All News &rarr;</a>
    </div>
    <app-news></app-news>
  </div>
</div>

<div class="index_sec6">
  <!--<div class="index_sec6_bg"></div>-->
  <div class="index_sec6_box contentCenter"  fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div class="index_sec6_box_col" fxFlex="100"  fxFlex.gt-sm="47">
      <img class="index_sec6_box_col_img" src="/assets/home/Forex-Traders-Summit-2024.webp" srcset="/assets/home/Forex-Traders-Summit-2024@2x.webp 2x" alt="Forex Traders Summit 2024 Award">
      <div class="index_sec6_palm"></div>
      <h3 class="index_sec6_box_h width900 m_t_mob" i18n="home|section 5 header 1">Forex Traders Summit 2024 Award</h3>
      <h2 class="index_sec6_box_hbig width900 m_t_mob" i18n="home|section 5 header 2">Best FX Service Provider in the Middle East</h2>

    </div>
    <div class="index_sec6_box_col" fxFlex="100"  fxFlex.gt-sm="47">
      <img class="index_sec6_box_col_img" src="/assets/home/Brokers-View-Expo-Abu-Dhabi-2024.webp" srcset="/assets/home/Brokers-View-Expo-Abu-Dhabi-2024@2x.webp 2x" alt="Brokers View Expo Abu Dhabi 2024">
      <div class="index_sec6_palm"></div>
      <h3 class="index_sec6_box_h width900 m_t_mob" i18n="home|section 5 header 1">Brokers View Expo Abu Dhabi 2024</h3>
      <h2 class="index_sec6_box_hbig width400 m_t_mob" i18n="home|section 5 header 2">Best FX Broker Middle East</h2>


    </div>




  </div>
</div>






