<div class="top_sec tutorials_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="tutorials|top header">TUTORIALS</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>


<div class="tutorials_sec1">
  <div class="contentCenter">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab i18n-label="tutorials|tab header 1" label="What is Forex" >


        <div class="m_t_mid block_img img_left">
          <img src="/assets/tutorials/tutorials_forex.webp" srcset="/assets/tutorials/tutorials_forex@2x.webp 2x" i18n-alt="tutorials|img 1" alt="What is Forex">
        </div>

        <div class="m_t_mid" >
          <div class="block_txt">
            <h2 class="block_txt_h m_t_mid" i18n="tutorials|tab 1 header 1">Forex</h2>
            <p class="m_t_mid" i18n="tutorials|tab 1 txt 1"><strong>FOREX</strong> stands for <strong>FOR</strong>eign <strong>EX</strong>change. The Foreign Exchange market is the biggest financial market in the world; with a daily turnover of over $5 trillion dollars (that's a whopping $5,000,000,000,000!). Almost all forex trades are of speculative nature; without physical delivery of currency, it's basically numbers flickering around on computer screens. Chances are your currency trades will be of the speculative kind, too.</p>

            <p class="m_t_mob" i18n="tutorials|tab 1 txt 2">So what is forex exactly? Forex trading is the simultaneous buying of one currency and selling of another. Every deal goes through intermediaries called brokers or dealers. Currencies are traded in pairs, for example euro vs. US dollar (EUR/USD), US dollar vs. Japanese yen (USD/JPY), US dollar vs. Canadian dollar (USD/CAD) and so on and so forth.</p>

            <p class="m_t_mob" i18n="tutorials|tab 1 txt 3">As you are not buying anything physical really, forex trading can be somewhat confusing. So, assume that when you buy a currency, it's like you're buying a piece of a country as the rate of exchange of a given currency against the other currencies is a reflection of the state of the economy of this given country in comparison with the economies of the other countries.</p>

            <p class="m_t_mob" i18n="tutorials|tab 1 txt 4">As opposed to other financial markets such as, for instance, the New York Stock Exchange, the London Stock Exchange, Bolsa de Madrid or Bursa Malaysia, the forex market is not based in any particular headquarters. It has no particular central exchange. The forex market is an over-the-counter (OTC) market - an interbank market, meaning that all the trades are executed electronically in a network of banks. The forex market “is open” non-stop for over 5 days a week, from late Sunday until the end of Friday.</p>


          </div>
        </div>

      </mat-tab>
      <mat-tab i18n-label="tutorials|tab header 2" label="Financial Markets" >




        <div class="m_t_mid block_img img_left">
          <img src="/assets/tutorials/tutorials_session.webp" srcset="/assets/tutorials/tutorials_session@2x.webp 2x" i18n-alt="tutorials|img 2" alt="Financial Markets">
        </div>

        <div class="m_t_mid" >
          <div class="block_txt">
            <h2 class="block_txt_h m_t_mid" i18n="tutorials|tab 2 header 1">Session One</h2>
            <p class="m_t_mid" i18n="tutorials|tab 2 txt 1">Every country is built on a certain economy; controlling, managing, sustaining is the main major role of an economy; Economy means the largest set of inter-related economic production and consumption activities which aid in determining how scarce resources are allocated.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 2">Each economy must encompass every single detail that is related to the production and consumption of goods and services in a certain allocated area, trying to sustain it in a way that might help to prosper and develop certain sectors.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 3">In each economy there are two different types of policies; fiscal and monetary policy:</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 4"><strong>The fiscal policy:</strong> is a policy stimulated by the policy makers, it is divided into taxation law and government spending; the government can adjust these laws in order to modify the amount of non-refundable income available to its tax payers, for example a government could ask more taxes from individual which makes them have less amount of money used in spending on goods and services, then the government could use those money to inject it back again into certain companies and markets by something called Government Spending. The higher the government spending means that they are going to demand more taxes from individuals in the economy; but the major disadvantage of this policy is that it might take time in order to achieve all that.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 5"><strong>The Monetary Policy:</strong> is the second type of polices used in managing a certain economy, this type of policy is mainly controlled by central banks, meaning that they control the supply of money into the economy, by putting cost on the borrowing of these money with something called Interest Rates. Interest rates are defined as the percentage amount of money charged on borrowed or lent money, as it could be variable or fixed.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 6">Another classification to an economy is, open and closed economy; an open economy means that it has bilateral trade with other economies all around the world for example the United States. A closed economy has a limited regulated trade with other world economies like china.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 7">Inside each economy there is a Financial System; Financial System is “found to organize the settlement of payments, to raise and allocate finance and to manage the risks associated with financing and exchange; developed to secure efficient payment system, security markets and financial intermediaries that arrange financing and derivative markets and financial institutions that provide access to risk management instruments”.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 8">There are two major roles of a financial system; the first one is to organize surplus funds from people and organizations, and to reallocate them into deficit facing organizations or people; as those mobilized funds are used to generate returns for the surplus entities, by enabling deficit entities to augment their productive and purchasing capacities.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 9">A financial system contains something called financial markets; a financial market is a mechanism that allows people to easily buy and sell financial securities, commodities, and other fungible items of value at low transaction costs and at prices that reflect efficient market hypothesis.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 10"><span class="_red_txt_">Securities:</span> “essentially a contract that can be assigned a value and traded”.
            </p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 11"><span class="_red_txt_">Commodities:</span> “a tangible good or product that are subject of sale or barter, such as grains, metals, and foods traded on commodities exchange or on spot market”.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 12">“it is a specified type of commodities; it is defined with a certain type of good that must be given without any changes in the contract”.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 13"><span class="_red_txt_">Transaction cost:</span> “cost incurred when buying or selling securities, which includes commission and spreads (the difference between the prices the dealer paid for a security and the prices at which it can be sold)”.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 14">Efficient Market hypothesis: “means that each share price in a market must reflect all relevant information”.</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 2 header 2">Session Two</h3>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 15">An Investor is a person who invests in any different type of markets, for example equities, commodities derivatives, currencies, real estate; as this term is connected with the individual who is looking for profit from a certain investment. Types of investors' can be classified on certain criterion which is known by risk (the possibility of suffering damage or loss).</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 16">Risk is calculated by dividing the standard deviation over the historical average returns.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 17">Risk = Standard Deviation /Average Returns</p>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 18">Speculators:</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 19">Are type of investors' who take higher than average risks, seeking for abnormal profits, as they are mainly concerned about speculating which might be the futuristic prices of a certain asset e.g. currency or a commodity; mainly they are involved in buying and selling future and option contracts in the short term, as they represent almost 70% if investors'; which might be known by "Risk Seekers".</p>
            <strong i18n="tutorials|tab 2 txt 20">Hedgers: </strong>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 21">Are types of investors' who tries to avoid or cancel any risks that can be accompanied with certain investment, they try to take positions that might prevent them from any potential losses, these types of investors' are widely found in markets that are full of uncertainties and high volatility. There are many types of hedging positions like natural hedges, hedging credit risk, hedging currency risk, hedging equity and equity futures. Which are also known by “Risk Neutrals”.</p>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 22">Arbitragers: </strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 23">Are types of investors' who buy securities in one market then immediately resell it in another market in order to profit from prices divergence, as this type of dealing is only suggested only for well experienced investors' as any delay in transactions could result of huge losses; the effect of these transaction would result in adjusting price differences between markets.</p>
            <p class="m_t_mob"><strong i18n="tutorials|tab 2 txt 24">Markets: </strong> </p>
            <p class="m_t_mob"><strong i18n="tutorials|tab 2 txt 25">There are three types of markets: </strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 26"><span class="_red_txt_">Factor Market:</span> it is the types of markets that include all features of production for example land, labor, capital.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 27"><span class="_red_txt_">Product Market:</span> is the market that includes all distributing products like food, goods and services.</p>
            <p class="m_t_mob"><strong i18n="tutorials|tab 2 txt 28">Financial Markets</strong></p>
            <p class="m_t_mob"><strong i18n="tutorials|tab 2 txt 29">Types of Financial Markets:</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 30"><span class="_red_txt_">Primary Market:</span> type of market that only sells the newly issued securities.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 31"><span class="_red_txt_">Secondary Market:</span> it’s a market where buyers buy from the seller rather than getting it from issuing company.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 32"><span class="_red_txt_">Over the Counter Market (OTC):</span> it’s a type of market that trades occur via phone or a network instead of a physical trading. Those types of markets are found for companies that do not meet the exchange listing requirements. Inside the OTC market there are:</p>

            <ul class="list_swfs m_t_mob">
              <li i18n="tutorials|tab 2 txt 33">Market makers: it’s a type of firm that takes in a certain type and number of shares in order to ease the trading in this security, each market maker displays buy and sell quotations for a certain number of shares, when the order is set the market maker instantly sells from his inventory, as this transaction takes only a small amount of time; for example NASDAQ is considered to be a market maker. The market maker profits from the spread, which is the difference between the prices for buying and the prices at which they are willing to sell at.</li>
              <li i18n="tutorials|tab 2 txt 34">Ask prices: is the price the seller is willing to take for a certain type of security and besides the ask prices there will be the amount of securities the market maker is willing to sell; which are also known by the Bears.</li>
              <li i18n="tutorials|tab 2 txt 35">Bid prices: the prices the buyer is willing to take, which is the opposite of the ask prices, the bulls in the markets are known by the bidders.</li>
            </ul>

            <p class="m_t_mob" i18n="tutorials|tab 2 txt 36"><span class="_red_txt_">Money Market:</span> is a type of market instruments that mature in less than one year as they are very liquid, the instruments involved in this market have fixed income and low risk for example treasury bills and commercial papers.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 37"><span class="_red_txt_">Capital Market:</span> it’s a market of trading more risky instruments with a longer maturity date, as this market consists of the primary and the secondary markets.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 38"><span class="_red_txt_">Bond Market:</span> “the place where the issuance and the trading of the debt securities occur”, as most bond market instruments are traded in the OTC market.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 39"><span class="_red_txt_">Stock Market:</span> is the market in which shares are traded through exchange floor or over-the-counter, which is known by another name equity market, this market helps investors' to have a partial ownership in a certain company, and some gains or dividends based on the company's performance.</p>
            <p class="m_t_mob" i18n="tutorials|40">A stock is defined as share of ownership in a certain company, and the more stocks you obtain the bigger your share in the company, which is confirmed by a certain piece of paper called a certificate. The managers of a company are supposed to increase the value of those investments to increase the confidence of investors' in the company to raise its share price.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 41">There are two types of stocks, preferred and common stocks. A common stock are type of shares that are released to public were anybody can acquire; a preferred stocks are another type of stocks that are sold to certain people, not publicly available. The difference between both types of stocks is that preferred stocks have the priority in taking distributed dividends and in the liquidation of the company.</p>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 42">Functions of Financial Markets</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 43"><span class="_red_txt_">Borrowing and lending:</span> financial markets provides money to investors', by giving out certain amount of money but with certain interests which is known by cost of borrowing.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 44"><span class="_red_txt_">Price determination:</span> sets or defines fixed or volatile prices for each type of instrument in the market.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 45"><span class="_red_txt_">Information collection and analysis:</span> provides information for market participants to value or estimate prices of a certain instrument.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 46"><span class="_red_txt_">&gt;Risk sharing: </span>financial markets eliminate a type of risk known by systematic risk, by diversifying investments.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 47"><span class="_red_txt_">Liquidity:</span> markets provide sufficient amount of buyers and sellers helping any investors' to directly convert instruments into cash.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 48"><span class="_red_txt_">Efficiency:</span> markets reflecting all the publicly available information on a certain instrument.</p>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 49">Major Market Participants </strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 50"><span class="_red_txt_">Broker: </span>a broker's job is to locate a buyer to the seller, as they involve in assets transformation.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 51"><span class="_red_txt_">Dealer:</span> smoothes the process of matching the buyer with the seller.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 52"><span class="_red_txt_">Investment banks:</span> contributes in selling the newly issued securities.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 53"><span class="_red_txt_">Financial intermediaries:</span> They are foundations that act as mediator between investors and firms.</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 2 header 3">Session Three</h3>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 54">Derivatives:</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 55">Are type of securities that their value is abstracted from other financial instruments; these types of derivates are used as a hedging bargain to stop any losses from any reversal movement in the market, so the main use is to "Control Risk"; it is mainly used with currency and interest rates.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 56">The derivative is used by the three types of investors:</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 57"><span class="_red_txt_">Hedgers: </span> the Hedger uses the derivates in order to minimize the losses by taking a position opposite to the transaction that he/she is having, so if the market reverse no major losses will occur.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 58"><span class="_red_txt_">Speculators:</span> the speculator will get in market just looking for abnormal profits, with accepting higher risk, by taking an open position.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 59"><span class="_red_txt_">Arbitragers: </span>the arbitrager try's to look for low risk profit, by taking advantage of difference in prices.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 60">A derivative includes different types of instruments:</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 61"><span class="_red_txt_">Forward Contracts:</span> are negotiated between two parties, to buy a long position and sell a short position of a specified amount of a commodity, as the buyer hold the right to undertake the action but not obligated, and setting the price (known by the spot price) of the commodity at a specified date.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 62"><span class="_red_txt_">Future Contracts:</span> are standardized contracts that are traded on a regulated floors, obligating the delivery of the agreed amount of commodity or a currency or an instrument for example treasury bonds, foreign currency. These types of contracts are considered to have low risk.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 63"><span class="_red_txt_">Options:</span> is a contract that designed to specify in it the quantity of a certain commodity with the specified date of transaction, were options have the right to buy or sell but not the obligation.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 64">There are two types of option contracts:</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 65"><span class="_red_txt_">Call option:</span> gives the buyer the right to buy (but not the obligation) a specified amount of securities at a certain price at a set date.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 66"><span class="_red_txt_">Put option:</span> gives the buyer the right to sell (but not the obligation) to the writer of the option by a certain prices and a specified date.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 67"><span class="_red_txt_">Swaps:</span> “is a flexible, private, forward-based contract or agreement, which is used to hedge against exchange risk from mismatched currencies on assets and liabilities”.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 68">There are different types risk associated with derivatives:</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 69"><strong>Basis risk:</strong> it is the price of the hedged asset subtracted from it the price of the derivative contract.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 70"><strong>Credit risk:</strong> it's the risk of the chance of one party offsetting the financial obligation under the contract.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 71"><strong>Market risk:</strong> it's the loss due to changes in the value of the derivative, including different types of risks control, accounting and legal risks.</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 2 header 4">Session Four</h3>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 72">Market Mechanism:</strong></p>
            <p class="m_t_mob" ><strong i18n="tutorials|tab 2 txt 73">Depth: </strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 74">Market depth is defined as the capability of the market to generate enough or extra orders without having an effect on the price of a security, as it can be defined in another way, it is measured also with the liquidity, the more liquid the markets are the more depth it has; moving security prices in large markets is really hard, because of the depth of the markets large orders must be taken in order to change prices slightly.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 75">There are certain factors which affect market depth:</p>

            <ul class="list_swfs m_t_mob">
              <li i18n="tutorials|tab 2 txt 76">Tick Size</li>
              <li i18n="tutorials|tab 2 txt 77">Prices movement restriction</li>
              <li i18n="tutorials|tab 2 txt 78">Trading restriction</li>
              <li i18n="tutorials|tab 2 txt 79">Allowable leverage</li>
              <li i18n="tutorials|tab 2 txt 80">Market Transparency</li>
            </ul>

            <p class="m_t_mob" i18n="tutorials|tab 2 txt 81">But there is a very big role that takes place in the markets that we can't ignore, which is known by investors' rationality, or market psychology.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 82">Markets in general are really built on speculations, forecasts and fears; if certain gossip spreads in markets average investors' would really panic which at the end leads them to take irrational acts, that could really affect the trend that is going into markets, but let's not forget the bigger influential which is known by attitude toward risk.</p>
            <p class="m_t_mob" i18n="tutorials|tab 2 txt 83">We all know a rational investor won't really take into consideration any spread of rumors into markets they will concentrate more risk; but the enigma starts when the amount of average investors' in markets gets bigger which would eventually affect the overall movement, obligating rational investors' to take different actions to wipe away any extra risk that can be added on them.</p>


          </div>
        </div>


      </mat-tab>
      <mat-tab i18n-label="tutorials|tab header 3" label="Profit & Loss Calculation" >

        <div class="m_t_mid block_img img_left">
          <img src="/assets/tutorials/profit-loss-calculation.webp" srcset="/assets/tutorials/profit-loss-calculation@2x.webp 2x" i18n-alt="tutorials|img 3" alt="Profit & Loss Calculation">
        </div>

        <div class="m_t_mid" >
          <div class="block_txt">

            <p class="m_t_mob" i18n="tutorials|tab 3 txt 1">Making profits in trading comes from expectations and speculations for prices. The concept is to buy a product hoping to sell it on a better/higher price or vice versa, and the difference is your profits - or losses if the market went against your trades and expectations.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 2">As an investor, your net realized profits will be the total profit that your order/trade has made minus your broker’s spreads, commissions, and/ or any other deductions.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 3">The equation to calculate profits or losses of a trade depends on the type of the traded instrument. For currencies, as an example, when you trade on a currency pair, the resulting profit amount is in the profit currency (second part of the currency pair). Therefore, in direct currencies where the profit currency is USD, profits are directly calculated in USD, while for indirect ones you need to exchange the resulting amount to USD according to the exchange rate at the time of closing the order.</p>
            <p class="m_t_mob" ><span class="_red_txt_" i18n="tutorials|tab 3 txt 4">Profit &amp; Loss Calculation for Currencies</span></p>
            <p class="m_t_mob" >For <strong i18n="tutorials|tab 3 txt 5">Direct Currencies:</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 6">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Lots</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 7">For <strong>Indirect Currencies:</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 8">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Lots / Closing Price</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 9">For <strong>Cross Currencies</strong> (where the profit currency is denominated in a direct currency):</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 10">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Lots X USD Exchange Rate</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 11">For Cross Currencies (where the profit currency is denominated in an indirect currency):</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 12">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Lots / USD Exchange Rate</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 3 header 1">Example 1</h3>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 13">You bought 2 lots of EURGBP at price 0.8648, in few days, the price goes up and you close your position at price 0.8702. The resulting profit will be as follows:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 14">Note: the exchange rate for GBPUSD at the time of closing the order is 1.5287</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 15">Profit = (Bid Price – Ask Price) X Contract Size X Number of Lots X Exchange Rate (USD)</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 16">= (0.8702 – 0.8648) X 100,000 X 2 X Exchange Rate for (GBPUSD) = 0.0054 X 200,000 X 1.5287 = 1,650.996 USD</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 17">And as you can trade in spot forex, so you can buy or sell futures contracts of currencies in the futures market for delivery on a specified future date.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 18">Futures contracts for currencies are all quoted in US Dollars. Therefore, your profits will be directly calculated in USD according to the following equation:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 19">For <strong>Futures Currencies: </strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 20">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Contracts</p>

            <p class="m_t_mob" ><span class="_red_txt_" i18n="tutorials|tab 3 txt 21">Profit &amp; Loss Calculation for Metals</span></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 22">As for precious metals, gold and silver prices are in USD, the profits/ losses are directly calculated in USD according to the simple equation below:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 23">For <strong>Precious Metals:</strong></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 24">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Lots</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 25">Same equation applies to futures contracts of precious metals as they have the same contract size and denominated in USD as well.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 26">Note that the contract size of 1 standard lot of sliver is 5,000 ounces, and for gold is 100 ounces.</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 3 header 2">Example 2</h3>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 27">You bought 3 contracts of Silver at price 19.86. In few days, the market goes up in your favor and you close your order at price 20.30.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 28">Your profits will be as follows:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 29">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Lots Profit/Loss = (20.30 – 19.86) X 5,000 Oz X 3.0 Lots Your Profit = 6,600 USD</p>
            <p class="m_t_mob" ><span class="_red_txt_" i18n="tutorials|tab 3 txt 30">Profit &amp; Loss Calculation for Indexes</span></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 31">Trading indices in futures contracts is to buy or sell an index in the futures market for delivery on a specified future date. An index is an imaginary portfolio of securities representing a particular market or a portion of it.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 32">The most common indices that are made available for OTC trading by most brokers are the US indices: S&amp;P 500, NASDAQ 100, and Dow Jones.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 33">When an index price goes up or down, the difference in points will be your profits or losses. Profit/Loss is calculated for indices according to the following equation:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 34">Profit/Loss = (Bid Price – Ask Price) X Index Point Value X Number of Contracts</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 35">Each index point has its determined value as in the indexes below:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 36">S&amp;P Point Value = $50 NASDAQ Point Value = $20 Dow Jones Point Value = $10</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 3 header 3">Example 3</h3>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 37">You placed a 2 contracts “Sell” order on S&amp;P for Month September (SP13SEP) at price 1700.25. In two weeks period, SPJUN price is still going up, so you decide to cut your losses and close the order at price 1704.75.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 38">Your losses will be calculated as follows:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 39">Loss = (Bid Price – Ask Price) X Index Point Value X Number of Contracts = (1700.25 – 1704.75) X $50 X 2.0 Lots = -450.00 USD (Loss)</p>
            <p class="m_t_mob" ><span class="_red_txt_" i18n="tutorials|tab 3 txt 40">Profit &amp; Loss Calculation for Energies</span></p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 41">Trading in futures contracts of energies is to buy or sell a CFD on energy contract in the futures market for delivery on a specified future date.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 42">The most common energy contracts that are made available for OTC trading by most brokers are Light Sweet Crude Oil, and Natural Gas.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 43">Sweet crude future contracts are the most popular oil contracts traded on commodity markets. 1 standard contract of Light Sweet Crude Oil equals a 1000 Barrels.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 44">Investors in the energy market can use Natural Gas futures contracts as well to profit from the changes in the underlying price. 1 standard contract of Natural Gas equals 10,000 MMbtu.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 45">Oil and natural gas prices are quoted in US Dollars. Therefore, your profits will be directly calculated in USD according to the following equation:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 46">Profit/Loss = (Bid Price – Ask Price) X Contract Size X Number of Contracts</p>
            <h3 class="m_t_mob" i18n="tutorials|tab 3 header 4">Example 4</h3>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 47">You bought 5 mini contracts of Light Sweet Crude Oil for Month May (CL13MAY) at price 100.95 USD a Barrel. You close your order on price 103.10 USD a Barrel.</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 48">Your profits will be calculated as follows:</p>
            <p class="m_t_mob" i18n="tutorials|tab 3 txt 49">Profit = (Bid Price – Ask Price) X Contract Size X Number of Contracts = (103.10 – 100.95) X 1000 Barrels X 0.5 Lot = 1075.0 USD</p>




          </div>
        </div>

      </mat-tab>
      <mat-tab i18n-label="tutorials|tab header 4" label="Technical Analysis" >


        <div class="m_t_mid block_img img_left">
          <img src="/assets/tutorials/tutorials_analysis.webp" srcset="/assets/tutorials/tutorials_analysis@2x.webp 2x" i18n-alt="tutorials|img 4" alt="Technical Analysis">
        </div>

        <div class="m_t_mid" >
          <div class="block_txt">


            <p class="m_t_mob" i18n="tutorials|tab 4 txt 1">When day trading, a trader makes the decision about what to trade, when to trade, and how to trade, using either fundamental or technical analysis. Both forms of analysis involve looking at the available information and making a decision about the future price of the market being traded, but the information that is used is completely different. It is possible to use both fundamental and technical analysis together, but it is more common for a trader to choose one or the other.</p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 2">Technical analysis is a method of forecasting price movements by analyzing statistics generated by market activity, such as previous prices and trading volume using charts and mathematical indicators to identify patterns that can suggest future activity, as it is based on the belief that the historical performance of stocks and markets are indications of future performance.</p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 3">Despite the technical tool that is used, technical analysis actually studies supply and demand in a market in an attempt to determine what direction or trend will continue in the future by studying the market itself.</p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 4">Almost every trader uses some form of technical analysis. Even fundamental analysis traders are likely to glance at price charts before executing a trade, as these charts help traders determine ideal entry and exit points for a trade. They provide a visual representation of the historical price action of whatever is being studied.</p>
            <p class="m_t_mob" ><span class="_red_txt_" i18n="tutorials|tab 4 txt 5">Types of Charts</span></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 6">Studying charts patterns is the basic concept of technical analysis. There are a variety of charts that show price action. The most common are bar charts. Each bar represents one period of time and that period can be anything from one minute to one month to several years. These charts will show distinct price patterns that develop over time.</p>
            <p class="m_t_mob"><img src="/assets/tutorials/TA-1.png" i18n-alt="tutorials|img 5" alt="Technical Analysis"></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 7">A simple bar chart shows opening and closing prices as well as highs and lows. The bottom of the vertical bar shows the lowest trade price for that time, while the top of the bar is the highest price that was paid. The vertical bar itself shows the instrument trading range in full. The horizontal line on the left is the opening price, while the horizontal line on the right is the closing price.</p>
            <p class="m_t_mob"><img src="/assets/tutorials/TA-2.png" i18n-alt="tutorials|img 6" alt="Technical Analysis"></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 8">Like bar charts patterns, candlestick patterns can be used to forecast the market. Because of their colored bodies, candlesticks provide greater visual detail in their chart patterns than bar charts so they are easier to follow.</p>
            <p class="m_t_mob" ><img  src="/assets/tutorials/TA-3.png" i18n-alt="tutorials|img 7" alt="Technical Analysis"></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 9">A candlestick chart indicates high to low with vertical line. The main body in the middle of this chart indicates the range between the opening and closing prices. If the block in the middle is colored in then the currency closed lower than it opened.</p>
            <p class="m_t_mob"><img  src="/assets/tutorials/TA-4.png" i18n-alt="tutorials|img 8" alt="Technical Analysis"></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 10">Line charts are one the most basic types of charts used in finance in general and forex in particular. This type of chart is formed through a line connecting a series of data points together; usually lines are drawn from one closing price to the next.</p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 11">Line charts provide a clear visualization of the general price fluctuation over a given period of time. One of the main reasons that make line charts so popular is that they record closing prices, one of the most important prices to keep track of.</p>
            <p class="m_t_mob"><img  src="/assets/tutorials/TA-5.png" i18n-alt="tutorials|img 9" alt="Technical Analysis"></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 12">Another unique charting technique used in technical analysis is the Point and figure patterns. They do not plot price against time as all other techniques do, but instead it plots price against changes in direction by plotting a column of Xs as the price rises and a column of Os as the price falls.</p>
            <p class="m_t_mob"><img  src="/assets/tutorials/TA-6.png" i18n-alt="tutorials|img 10" alt="Technical Analysis"></p>
            <p class="m_t_mob"><span class="_red_txt_" i18n="tutorials|tab 4 txt 13">Technical Indicators</span></p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 14">Besides studying chart patterns, there are other varied and more sophisticated technical tools and mathematical indicators available. The most commonly used are technical indicators, measuring support and resistance, and using trendlines; although all three can be considered as technical indicators as they all rely on looking at the chart and reviewing recent history trying to spot whether a price is following a pattern or moving in a range.</p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 15">technical indicator is a graphical representation resulting from calculations based on the price action and is usually displayed along the bottom of the chart. A wide range of technical indicators are widely used by many traders. They can be categorized according to what they describe and what they indicate.</p>

            <ul class="list_swfs m_t_mob" >
              <li i18n="tutorials|tab 4 txt 16"><strong>Trend indicators:</strong> trend is a term used to describe the persistence of price movement in one direction over time. Trends move in three directions: up, down and sideways. Trend indicators smooth variable price data to create a composite of market direction. (Example: Moving Averages, Trendlines).</li>
              <li i18n="tutorials|tab 4 txt 17"><strong>Strength indicators:</strong> Market strength describes the intensity of market opinion with reference to a price by examining the market positions taken by various market participants. Volume and open interest are the basic ingredients of this indicator. Their signals are coincident or leading the market. (Example: Volume).</li>
              <li i18n="tutorials|tab 4 txt 18"><strong>Volatility indicators:</strong> Volatility is a general term used to describe the magnitude, or size, of day-to-day price fluctuations independent of their direction. Generally, changes in volatility tend to lead changes in prices. (Example: Bollinger Bands).</li>
              <li i18n="tutorials|tab 4 txt 19"><strong>Cycle indicators:</strong> A cycle is a term to indicate repeating patterns of market movement, specific to recurrent events, such as seasons, elections, etc... Many markets have a tendency to move in cyclical patterns. Cycle indicators determine the timing of a particular market patterns. (Example: Elliott Wave).</li>
              <li i18n="tutorials|tab 4 txt 20"><strong>Support &amp; Resistance indicators:</strong> Support and resistance describes the price levels where markets repeatedly rise or fall and then reverse. This phenomenon is attributed to basic supply and demand. (Example: Trendlines).</li>
              <li i18n="tutorials|tab 4 txt 21"><strong>Momentum indicators:</strong> Momentum is a general term used to describe the speed at which prices move over a given time period. Momentum indicators determine the strength or weakness of a trend as it progresses over time. Momentum is highest at the beginning of a trend and lowest at trend turning points. Any divergence of directions in price and momentum is a warning of weakness; if price extremes occur with weak momentum, it signals an end of movement in that direction. If momentum is trending strongly and prices are flat, it signals a potential change in price direction. (Example: Stochastic, MACD, RSI).</li>
            </ul>
            <p class="m_t_mob"><span class="_red_txt_" i18n="tutorials|tab 4 txt 22">Benefits of Technical Analysis</span></p>

            <ul class="list_swfs m_t_mob">
              <li i18n="tutorials|tab 4 txt 23">Technical analysis focuses on price movement which is easily spotted at a glance through the charts.</li>
              <li i18n="tutorials|tab 4 txt 24">Technical analysis applies to all charts, whether they are 5-minute charts for day trading or daily charts for longer term trading.</li>
              <li i18n="tutorials|tab 4 txt 25">Trends are easily found as some indicators can quickly display a currency or security that is exhibiting a trend.</li>
              <li i18n="tutorials|tab 4 txt 26">Patterns are easily identified as charts displays previous patterns and help predicting future ones, as the market tends to repeat itself.</li>
              <li i18n="tutorials|tab 4 txt 27">Charting is quick and inexpensive as such technical indicators and tools are available via the net and they are automatically represented according to built-in calculations. Most brokers nowadays offer them to clients as part of their package.</li>
              <li i18n="tutorials|tab 4 txt 28">Charts and indicators can provide a huge amount of information in only a few moments. There are more than fifty kinds of indicators and each provides information on different aspect of how a currency or a security is moving.</li>
            </ul>

            <p class="m_t_mob" i18n="tutorials|tab 4 txt 29">How technical analysis applies can be different for each trader. Every trader has their own interpretation of where they see trends and support. They also have their own ideas on setting up their indicators. The indicators and charts can be simple or sophisticated as they can provide only the most basic information on a trend or support and resistance, or go much deeper to provide information on the strength of a trend, how momentum is building, and whether formations are developing that the can be traded.</p>
            <p class="m_t_mob" i18n="tutorials|tab 4 txt 30">It is important to understand that technical analysis is not an exact science, and may not always give accurate predictions of the market movement; however, it is very useful in forex trading. It makes up only one portion of what you need to know when trading, but it is a very important thing to learn. Understanding technical analysis will give the charts some meaning when you look at them and help you understand why certain price movements occurred.</p>



          </div>
        </div>


      </mat-tab>

    </mat-tab-group>
  </div>
</div>
