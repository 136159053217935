<!-- mobile -->
  <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" routerLink="{{url}}" [fragment]="item.anchor" (click)="onItemSelected(item)" [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}">

    {{item.displayName}}<span fxFlex></span>
    <div class="arrow" *ngIf="!item.children && item.route ? router.isActive(item.route, true): false">
      <div class="point"></div>
    </div>
    <span fxFlex *ngIf="item.children && item.children.length">
      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">expand_more</mat-icon>
    </span>
  </a>
  <span *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [parentPath]="parentPath" [depth]="depth+1">  </app-menu-list-item>
  </span>



