import {Component, OnInit} from '@angular/core';
import {NavService} from '@core/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;
  public localesList: any;
  public currentKey: any;

  constructor(public nav: NavService) {
    let i;
    console.log(nav.locale);
    for (i = 0; i <= 1; i++) {
      if (nav.localesList[i].code === nav.locale) {
        this.currentKey = i;
      }

    }
    console.log(nav.localesList[0]);
    nav.localesList.splice(this.currentKey, 1);
    this.localesList = nav.localesList[0];
  }


  ngOnInit() {
  }
}
