<div class="top_sec tutorials_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="tutorials|top header">PRIVACY POLICY</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 25, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.04, initialValue: 45, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 75, cssUnit:'%'}]"></div>

</div>


<div class="privacy_sec1">
  <div class="contentCenter_min">

    <h2 >PRIVACY POLICY – Swiss for Direct Trading in Currencies, Metals and Commodities.</h2>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities. ("SwissFS") believes that the privacy and security of an individual’s personal financial information is important. Swiss for Direct Trading in Currencies, Metals and Commodities is committed to its privacy obligations under applicable law and regulation. This privacy policy describes how we handle and protect personal information we collect about individuals who apply for or receive our products and services. This policy describes how Swiss for Direct Trading in Currencies, Metals and Commodities collects and uses information through its site <a class="privacy_sec1_link" href="https://swissfs.com/" rel="dofollow" aria-label="www.swissfs.com">www.swissfs.com</a> and any other related sites and applications. This Privacy Policy does not apply to websites operated by third parties, inclusive of introducing brokers.</p>


    <h2 class="m_t_mid">INFORMATION COLLECTION</h2>

    <p class="m_t_mob_s">In order to obtain an account with Swiss for Direct Trading in Currencies, Metals and Commodities, you must first complete and send an application form by completing the required information. By completing the application form you are requested to disclose private information in order to enable Swiss for Direct Trading in Currencies, Metals and Commodities to evaluate your application and comply with relevant rules and regulations. This information may also be used by Swiss for Direct Trading in Currencies, Metals and Commodities to inform you regarding its services. Moreover, information about you may also be collected by any correspondence made with us by phone, e-mail or otherwise; including information which you enter when you register to use our site(s), subscribe to our service or any other activities carried out on our website or any connected applications.</p>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities may collect data detailing how you discovered our site(s) and the frequency of visits. This information is obtained in order to improve the content of Swiss for Direct Trading in Currencies, Metals and Commodities site(s) and may also be used by Swiss for Direct Trading in Currencies, Metals and Commodities to contact you, through appropriate means, and provide you with any information Swiss for Direct Trading in Currencies, Metals and Commodities believes to be useful.</p>

    <p class="m_t_mob_s">With regards to each of your visits to our site, we may, automatically collect information including internet protocol (IP) address, login information, browser type and version, time zone, phone numbers used to call our customer service number. We may receive information about you from third parties, for instance business partners, sub-contractors, advertising networks, analytics providers, search information providers, credit reference agencies.</p>

    <h2 class="m_t_mid">INFORMATION DISCLOSURE</h2>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities will not disclose to a third party, any of its clients’ confidential information unless required to do so by law enforcement or regulatory authority of a competent jurisdiction. Moreover, Swiss for Direct Trading in Currencies, Metals and Commodities will make best efforts to make such disclosures on a ‘need-to-know’ basis, unless otherwise instructed by a regulatory authority. Under such circumstances, SwissFS shall expressly inform the third party regarding the confidential nature of the information.</p>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities may share your personal information with any affiliate or subsidiary of our group, inclusive of holding companies. We may also share your information with certain third parties including business partners, suppliers and sub- contractors and advertisers. In the event that Swiss for Direct Trading in Currencies, Metals and Commodities discloses information to business parties, for instance, card processing companies or banks, in order to effect the services requested by the clients; such third parties may store your information in order to comply with their legal and other obligations.</p>


    <h2 class="m_t_mid">DISCLOSURE OF FORMER CUSTOMER INFORMATION</h2>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities does not disclose any confidential nonpublic personal information of former customers, except as permitted or required by applicable law. Thus, should you close your account and become an inactive/former customer we will adhere to the measures delineated in this privacy policy.</p>

    <h2 class="m_t_mid">INFORMATION SAFEGUARDS</h2>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities maintains physical, electronic and procedural safeguards to guard your nonpublic personal information. As a result, only authorized employees who need to know your information in order to provide you with products or services will have access to your nonpublic personal information. We restrict the access of authorized employees to nonpublic personal information for business purposes only. These safeguards are used by all Swiss for Direct Trading in Currencies, Metals and Commodities affiliates. However, you acknowledge and accept that any services provided through our website involve transmissions over the internet and that such transmissions are therefore subject to the internet’s inherent risks. Whilst we acknowledge our responsibility to take reasonable security precautions, you also acknowledge and accept that, as with any network, you may also be exposed to unauthorized programs transmitted by third parties, electronic trespassing and/or the failure of information and data to reach their intended destinations and/or erroneous receipt or misdirection of such information. Although our and our suppliers’ privacy and security features are designed to reduce these risks, we cannot guarantee that the risk can be eliminated completely. You therefore acknowledge that we will not be liable for any breach of confidence or unauthorized access and use arising as a result of such event.</p>

    <h2 class="m_t_mid">OPT-OUT PROCEDURE</h2>

    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities has set forth its disclosure policy within the Information Disclosure section herein. Swiss for Direct Trading in Currencies, Metals and Commodities is required to provide an opt-out statement. You are not required to supply any of the personal information requested by Swiss for Direct Trading in Currencies, Metals and Commodities, however, failure to do so will likely result in Swiss for Direct Trading in Currencies, Metals and Commodities inability to open your account or continue servicing your existing account.</p>





    <h2 class="m_t_mid">CHANGES TO YOUR PERSONAL INFORMATION</h2>
    <p class="m_t_mob_s">You may inform Swiss for Direct Trading in Currencies, Metals and Commodities at any time that your Information has changed or that you wish Swiss for Direct Trading in Currencies, Metals and Commodities to delete information we hold about you. We will change or delete your Information in accordance with your instructions, except to the extent that we are required to hold your Information for regulatory or legal purposes, to provide you with the services you have requested or to maintain adequate business records.</p>


    <h2 class="m_t_mid">LEGAL DISCLAIMER</h2>
    <p class="m_t_mob_s">Swiss for Direct Trading in Currencies, Metals and Commodities reserves the right to disclose your personally identifiable information as required by applicable laws and regulations and when Swiss for Direct Trading in Currencies, Metals and Commodities believes that disclosure is necessary to protect Swiss for Direct Trading in Currencies, Metals and Commodities rights or the rights of affiliates and subsidiaries or to comply with any judicial and/or other proceedings, court order, legal process served or pursuant to governmental, intergovernmental and/or other regulatory bodies. Swiss for Direct Trading in Currencies, Metals and Commodities shall not be liable for unlawful or unauthorized use of your confidential or personal information due to sharing or misplacement of your passwords, negligent or malicious intervention or otherwise.</p>


    <h2 class="m_t_mid">CHANGES TO THIS POLICY</h2>
    <p class="m_t_mob_s">If we decide to change our privacy policy, we will post those changes to this privacy statement, the page, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>

    <p class="m_t_mob_s">We reserve the right to modify this privacy policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our home page.</p>

  </div>
</div>
