export const environment = {
  production: true,
  serviceWorker: false,
  baseHref: '/',
  language: {id: '3', name: 'English', tiny: 'en', full: 'en_US'},
  confirm: {
    email: '',
    password: ''
  },
  hosts: {
    'api.bmfn.us': {
      mailTo: 'admin',
      apiUrl: '',
      pcUrl: 'https://my.swissfs.com'
    },
    /*'sifxtrading.com': {
      mailTo: 'admin',
      apiUrl: 'https://api.swissfs.com',
      pcUrl: 'https://my.sifxtrading.com'
    },*/
    'swissfs.com': {
      mailTo: 'admin',
      apiUrl: 'https://api.swissfs.com',
      pcUrl: 'https://my.swissfs.com'
    },
    default: {
      mailTo: 'admin',
      apiUrl: 'https://aws.bmfn.com',
      pcUrl: 'https://my.swissfs.com'
    }
  }
};
