import {Component, OnInit} from '@angular/core';
import {NavService} from '@core/nav.service';

@Component({
  selector: 'app-company',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss']
})
export class BonusComponent implements OnInit {

  constructor(public nav: NavService) {}
  pcUrl = this.nav.env.pcUrl;

  ngOnInit(): void {
  }


}
