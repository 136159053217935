import { Component, OnInit } from '@angular/core';
import {NavService} from '@core/nav.service';

@Component({
  selector: 'app-for-partners',
  templateUrl: './for-partners.component.html',
  styleUrls: ['./for-partners.component.scss']
})
export class ForPartnersComponent implements OnInit {

  constructor(public nav: NavService) {}
  pcUrl = this.nav.env.pcUrl;

  ngOnInit(): void {
  }

}
